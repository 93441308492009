<template>
  <div class="container-fluid mt--6 api-page">
    <div class="card mb-3">
      <div class="card-header">Error page</div>
      <div class="card-body">
        <div class="error-403-container">
          <h1>Error: 403 Forbidden</h1>
          <h2>403</h2>
          <h3>
            {{ $route.query.message || "You dont have access to this page" }}
          </h3>
          <el-button v-if="userplus" type="primary" @click="$router.push('/')"
            >Go back</el-button
          >
          <el-button
            v-if="!userplus"
            type="primary"
            @click="$router.push('/wallet/dashboard')"
            >Go back</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Page403",
  computed: {
    userplus() {
      return this.$store.getters.usertype == "plus";
    },
    plusuid() {
      return this.$store.getters.plusuid;
    },
  },
  mounted() {
   
  },
};
</script>
<style lang="scss">
.error-403-container {
  text-align: center;
  margin-top: 100px;
  color: #828384;
  font-size: 1.5vmin;
  h1 {
    font-size: 3vmin;
    margin-bottom: 0;
  }
  h2 {
    font-size: 15vmin;
    margin-top: 0;
    margin-bottom: 40px;
    color: #f56c6c;
    letter-spacing: 5px;
    font-weight: 700;
    text-shadow: 10px 9px 3px #535f05;
    transition: all 1s linear;
  }
}
</style>